<!-- Localized -->
<template>
    <div>
        <card-template
            :item="item"
            :selected="selected"
            :compact="compact"
            class="card-email"
            :class="{
                'card-email--compact': !!compact,
            }"
        >
            <template #label-after>
                <notification v-if="compact && item.thread && item.thread.length" :count="item.thread.length" />
            </template>
            <template #content>
                <div class="property property--subject" v-html="item.subject">
                    <notification v-if="item.thread && item.thread.length" :count="item.thread.length" />
                </div>
                <div class="property property--from">{{ $t('components.dashboard.email.from_s', [item.email_from]) }}</div>
                <div class="property property--to">{{ $t('components.dashboard.email.to_s', [item.email_to]) }}</div>
                <div class="flex justify-end">
                    <button v-if="item.attachments && item.attachments.length > 0" @click.stop="showActionModal(item, 'email-attachments')" class="p-2 relative rounded-lg hover:bg-gray-100 flex items-center gap-2 font-semibold cursor-pointer">
                        <font-awesome-icon class="text-lg text-blue-600" icon="link" />
                        <div class="rounded-full bg-blue-600 text-xs p-0.5 absolute -top-1 left-5 text-white" style="min-width: 20px; min-height: 20px;">
                          {{checkAttachmentsLength(item.attachments.length)}}
                        </div>
                        {{ $t('components.dashboard.email.attachments') }}
                    </button>
                </div>
                <div ref="dynamicContent" class="property property--description" v-html="item.html_body"></div>
            </template>
            <template #content-compact>
                <div class="property property--subject" v-html="item.subject"></div>
                <div class="property property--date" v-if="item.date">
                    {{ FormatDate(item.date) }}
                </div>
            </template>
            <template #actions>
                <slot name="actions"> </slot>
            </template>
        </card-template>
    </div>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import { FormatDate } from "@/plugins/functions";
const notification = () => import("@/components/dashboard-components/checklist/components/notification.vue");

export default {
    name: "card-email",
    components: {
        cardTemplate,
        notification,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: false,
        },
        compact: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            loadingStates: {
                attachments: false,
            },
            attachments: [],
        };
    },
    mounted() {
        this.applyCustomAnchorColor();
    },
    methods: {
        FormatDate,
        applyCustomAnchorColor() {
            let dynamicContent = this.$refs.dynamicContent;
            let anchorTag = dynamicContent.querySelector('a');
            if (anchorTag) {
                anchorTag.style.color = 'blue';
            }
        },
        async showActionModal(email, type) {
          let payload = {data:email, type}
          this.$emit('showEmailAttachments', payload)
        },
        checkAttachmentsLength(length){
          return length > 10 ? '10+' : length
        }
    },
};
</script>

<style lang="scss" scoped></style>
